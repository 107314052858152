import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './styles/public.css'
import TreeTable from 'vue-table-with-tree-grid'
import 'element-ui/lib/theme-chalk/index.css'
import element from './plugins/element.js'
import * as echarts from 'echarts'
Vue.prototype.$echarts=echarts
Vue.use(element)
import VueAMap from 'vue-amap';
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  // 高德的key
  // key: 'f7c4540c61e4babad7051ecda0ffa5aa',
  key:"94a2ff8a0842f42fb78787c63ecd0a35",
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  v: '1.4.4'
});
Vue.config.productionTip = false
Vue.component('tree-table',TreeTable)
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
