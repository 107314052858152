import Vue from 'vue'
import VueRouter from 'vue-router'
const Login = () => import('../views/login.vue')
const Visualization = () => import('../views/visualization.vue')
const Visualizations = () => import('../components/visualizations.vue')
const Location = () => import('../components/location.vue')
const Home = () => import('../views/home.vue')
const Index = () => import('../components/index.vue')
const Emotion = () => import('../components/emotion.vue')
const Equipment = () => import('../components/equipment.vue')
const Permissions = () => import('../components/permissions.vue')
const Department = () => import('../components/department.vue')
const Personnel = () => import('../components/personnel.vue')
const Account = () => import('../components/account.vue')
const Team = () => import('../components/team.vue')
const Power = () => import('../components/power.vue')
const Warning = () => import('../components/warning-list.vue')
const Monttoring = () => import('../components/monitoring-list.vue')
const Log = () => import('../components/log.vue')
const Xhpanel = () => import('../components/xhPanel.vue')
Vue.use(VueRouter)

const routes = [{
		path: '/',
		redirect: '/login'
	}, {
		path: '/visualization',
		name: 'visualization',
		component: Visualization
	},
	{
		path: '/visualizations',
		name: 'visualizations',
		component: Visualizations
	},
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/xhpanel',
		name: 'xhpanel',
		component: Xhpanel
	},
	{
		path: '/home',
		name: 'home',
		component: Home,
		// redirect:'/index',
		children: [{
				path: '/index',
				name: '首页',
				component: Index,
			},
			{
				path: '/equipment',
				name: '设备管理',
				component: Equipment,
			},
			{
				path: '/emotion',
				name: 'emotion',
				component: Emotion,
			},
			{
				path: '/permissions',
				name: '权限管理',
				component: Permissions,
				beforeEnter: (to, from, next) => {
					let level = sessionStorage.getItem('level')
					if (level == 1 || level == 2) {
						next()
					} else {
						next('/login')
					}
				}
			},
			{
				path: '/department',
				name: '部门管理',
				component: Department,
				beforeEnter: (to, from, next) => {
					let level = sessionStorage.getItem('level')
					if (level == 1) {
						next()
					} else {
						next('/login')
					}
				}
			},
			{
				path: '/personnel',
				name: '人员管理',
				component: Personnel,
			},
			{
				path: '/account',
				name: '账号设置',
				component: Account,
			},
			{
				path: '/location',
				name: '定位地图',
				component: Location
			},
			{
				path: '/warning',
				name: '预警记录',
				component: Warning
			},
			{
				path: '/monttoring',
				name: '监测记录',
				component: Monttoring
			},
			{
				path: '/log',
				name: '操作日志',
				component: Log
			},

			{
				path: '/team',
				name: 'team',
				component: Team,
				beforeEnter: (to, from, next) => {
					let level = sessionStorage.getItem('level')
					if (level == 0) {
						next()
					} else {
						next('/login')
					}
				}
			},
			{
				path: '/power',
				name: 'power',
				component: Power,
				beforeEnter: (to, from, next) => {
					let level = sessionStorage.getItem('level')
					if (level == 0) {
						next()
					} else {
						next('/login')
					}
				}
			},
		]
	}
]
const router = new VueRouter({
	// mode:'history',
	// base:process.env.BASE_URL,
	routes
})
router.beforeEach((to, from, next) => {
	if (to.name) {
		document.title = to.name
		sessionStorage.setItem('pageName', to.name)
	}
		
	if(to.name=='xhpanel'){
		return next()
	}
	if (to.name != 'login') {
		let token = sessionStorage.getItem('token')
		if (token) {
			next()
		} else {
			next('/login')
		}
	} else {
		next()
	}
})
export default router
